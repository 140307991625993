<template>
  <div>
    <cc-nav-bar v-show="!(['auth.signin', 'auth.signup'].indexOf($route.name) > -1)"></cc-nav-bar>
    <div class="columns">
      <cc-menu v-show="!(['auth.signin', 'auth.signup'].indexOf($route.name) > -1)"></cc-menu>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
// import { setToken } from './plugins/http';
import CcNavBar from './components/navbar.vue';
import CcMenu from './components/menu.vue';

export default {
  name: 'app',
  components: {
    CcNavBar,
    CcMenu,
  },
  methods: {
    ...mapActions(['setUser']),
  },
  /* eslint-disable */
  mounted() {
    if (localStorage.user) {
      this.setUser(JSON.parse(localStorage.user));
    }
  },
};
</script>
